import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Guest.css';
import {useMediaQuery} from 'react-responsive';
import swal from 'sweetalert';
import bgWeb from '../../assets/images/register/bgw.png';
import bgMobile from '../../assets/images/register/bgmobile.png'
import khungWeb from '../../assets/images/register/khungw.png';
import khungMobile from '../../assets/images/register/khungm.png'
import apply from '../../assets/images/register/apply.png';
import cont from '../../assets/images/register/tieptuc.png'
import axios from "axios";

const GuestUpdate = () => {
    const [valid, setValid] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [uid, setUid] = useState(-1);
    const [invalidText, setInvalidText] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showInput, setShowInput] = useState(0);

    const togglePasswordVisibility = (type) => {
        if (type === 'password') {
            setShowPassword(!showPassword);
        } else if (type === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handleContinue = () => {
        setShowInput(1); // Khi người dùng ấn nút "Tiếp tục", đặt state showInput thành true để hiển thị phần input
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isPortrait = useMediaQuery({orientation: 'portrait'});
    let bg, khung;

    if (isPortrait) {
        bg = bgMobile;
        khung = khungMobile;
    } else {
        bg = bgWeb;
        khung = khungWeb;
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/user/force-update-guest-check`, {
            code: code
        })
            .then(response => {
                const {status, uid, reason} = response.data;
                if (status === 'OK') {
                    setValid(true);
                    setUid(uid)
                } else {
                    setValid(false);
                    setInvalidText(reason)
                }
            })
            .catch(error => {
                setValid(false);
            });
    }, []);

    const UpdateAcc = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');

        if (password !== '' && username !== '') {
            if (!isChecked) {
                swal('Vui lòng đồng ý với điều khoản của Sân Đình', '', 'warning')
            } else {
                if (username.length < 5) {
                    swal('Tên đăng ký phải có ít nhất 5 kí tự', '', 'warning')
                } else if (username.length > 20) {
                    swal('Tên đăng ký nhiều hơn 20 kí tự', '', 'warning')
                } else if (password !== confirmPassword) {
                    swal('Xác nhận mật khẩu không trùng khớp', '', 'warning')
                } else if (password.length < 6) {
                    swal('Mật khẩu phải có ít nhất 6 kí tự', '', 'warning')
                } else {
                    console.log('Thông tin đổi mật khẩu:', {username, password, confirmPassword});

                    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/user/force-guest-upgrade`, {
                        code: code,
                        username: username,
                        pass: password
                    })
                        .then(response => {
                            let {status, reason} = response.data;

                            if (status === 'OK') {
                                setShowInput(2);
                            } else {
                                if (reason === 'Yêu cầu không được thực hiện!')
                                    reason = reason + " Vui lòng tắt trình duyệt, tắt game và thực hiện lại từ đầu."
                                swal('Thất bại', reason, 'error');
                            }
                        })
                        .catch(error => {
                            swal('Thất bại', "Có lỗi xảy ra, vui lòng liên hệ CSKH để được hỗ trợ", 'error');
                        });
                }
            }
        } else {
            swal('Chưa nhập đầy đủ thông tin', '', 'warning')
        }
    };

    if (!valid) {
        const texts = invalidText.split("\n")

        return (
            <div className="container justify-content-center">
                <img src={bg} className="background-image" alt="Background"/>

                <img src={khung} className="khung-image" alt="Form"/>
                <div className="hello">
                    <h3 className="text-red text-notice">{texts[0]}</h3>
                    <h3 className="text-red text-notice">{texts[1]}</h3>
                </div>
            </div>
        )
    }

    return (
        <div className="container justify-content-center">
            <img src={bg} className="background-image" alt="Background"/>
            <img src={khung} className="khung-image" alt="Form"/>

            {showInput === 0 ? (
                <div className="hello">
                    <p className="text-red text-notice text-left">- Việc nâng cấp này giúp bạn có tài khoản và mật khẩu đăng nhập game, có thể sử dụng để chơi ở tất cả các thiết bị và tất cả các phiên bản game của Sân Đình.</p>
                    <p className="text-red text-notice text-left">- Bảo, Công thần, kinh nghiệm, bằng cấp… của người chơi được bảo toàn sau khi nâng cấp.</p>
                    <p className="text-red text-notice text-left">- Lưu ý: Bạn cần ghi nhớ tên tài khoản, mật khẩu để đăng nhập lần sau.</p>

                    <div className="row d-flex justify-content-center align-items-center">
                        <button className="image-button2" type="button" onClick={handleContinue}>
                            <img src={cont} alt="Button Image"/>
                        </button>
                    </div>
                </div>
            ) : (<div></div>)}

            {showInput === 1 ? (
                <div className="hello">
                    <h3 className="text-red text-update">Nâng cấp tài khoản Sân Đình cho ID: {uid}</h3>
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-auto">
                            <input type="text" className="c-input-1" placeholder="Tên tài khoản"
                                   value={username} onChange={(e) => setUsername(e.target.value)}/>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-auto">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                className="c-input-1"
                                placeholder="Mật khẩu"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="col-auto show-password-button">
                            <div
                                onClick={() => togglePasswordVisibility('password')}
                            >
                                {showPassword ? 'Ẩn' : 'Hiện'}
                            </div>
                        </div>
                    </div>

                    {/* Ô input xác nhận mật khẩu */}
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-auto">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                className="c-input-1"
                                placeholder="Xác nhận mật khẩu"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <div className="col-auto show-password-button">
                            <div
                                onClick={() => togglePasswordVisibility('confirmPassword')}
                            >
                                {showConfirmPassword ? 'Ẩn' : 'Hiện'}
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center align-items-center c-checkbox">
                        <div className="col-2" style={{padding: 0}}>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={(e) => setIsChecked(e.target.checked)}
                                className="custom-checkbox"
                            />
                        </div>
                        <div className="col-10" style={{padding: 0}}>
                            <label className="custom-label text-back text-update">Tôi đồng ý với điều khoản của Sân Đình</label>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center align-items-center">
                        <button className="image-button2" type="button" onClick={UpdateAcc}>
                            <img src={apply} alt="Button Image"/>
                        </button>
                    </div>
                </div>) : (<div></div>)}

            {showInput === 2 ? (
                <div className="hello">
                    <p className="text-red text-notice text-left">- Chúc mừng bạn đã nâng cấp tài khoản thành công.</p>
                    <p className="text-red text-notice text-left">- Bạn đã có thể sử dụng tài khoản <b>{username}</b> để đăng nhập chơi game Sân Đình</p>
                    <p className="text-red text-notice text-left">- Hãy quay trở lại game và sử dụng tài khoản <b>{username}</b> để đăng nhập</p>
                </div>
            ) : (<div></div>)}
        </div>
    );
};

export default GuestUpdate;
