import logo from '../logo.svg';
import './App.css';
import GuestUpdate from "./Guest/GuestUpdate";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <GuestUpdate></GuestUpdate>
      </header>
    </div>
  );
}

export default App;
